import React from 'react'
import styled from 'styled-components'
import OfferProductsSetting from './offerProductsSetting'
import Customization from './customization'
import ABTesting from './abTesting'
import SmartRulesCard from './smartRulesCard';
const ManualRulesWrapper = styled.div`
    margin-top: 20px;
`
function ManualRules(props) {
    const { host, token, versionAnalytics, versionData, setVersionData, version, setVersion, checkoutOfferType, shop, addSmartRule, state, setState, productPickerShow, setProductPickerShow, error, setError, productEdit, setProductEdit, currency, locale, currencySymbol } = props

    return (
        <ManualRulesWrapper>
          <OfferProductsSetting host={host} token={token} version={version} shop={shop} state={state} setState={setState} productPickerShow={productPickerShow} error={error} setError={setError} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol}/>
          <div style={{marginTop: '20px'}}>
          <Customization version={version} checkoutOfferType={checkoutOfferType} state={state} setState={setState} currency={currency} currencySymbol={currencySymbol}/>
          {"Cart" !== state.offerType ?
           <ABTesting versionAnalytics={versionAnalytics} versionData={versionData} setVersionData={setVersionData} checkoutOfferType={checkoutOfferType} version={version} setVersion={setVersion} state={state} setState={setState} locale={locale} currency={currency}/>
           :
           <div style={{marginBottom:'20px'}}>

           </div>
          }
            <SmartRulesCard
              host={host}
              shop={shop}
              token={token}
              addSmartRule={addSmartRule}
              state={state}
              setState={setState}
              productPickerShow={productPickerShow}
              setProductPickerShow={setProductPickerShow}
              currencySymbol={currencySymbol}
            />
          </div>
        </ManualRulesWrapper>
    )
}

export default ManualRules;
import React, {useMemo} from 'react';
import { LegacyCard, FormLayout, TextField, Text } from "@shopify/polaris";
import update from "immutability-helper";

const CalloutBannerCard = ({state, setState, fields}) => {
  const fieldsArr = [
    {
      name: 'banner',
      title: 'Callout Banner',
      placeholder: 'Frequently Bought together!'
    },
    {
      name: 'introText',
      title: 'Intro Text',
      placeholder: 'Add this product to your order now!'
    }
  ];

  const renderFields = useMemo(() => {
    return fieldsArr
      .filter(({name}) => fields.includes(name))
      .map(({name, title, placeholder},  index) => {
        return (
          <TextField
            key={index}
            label={<Text  variant="bodyMd" as="span">{title}</Text>}
            placeholder={placeholder}
            onChange={(val) => {
              setState(update(state, { [name]: { $set: val }}))
            }}
            value={state[name]}
            autoComplete="off"
          />
        );
      });
  }, [state, setState]);

  return (
        <FormLayout>
          {renderFields}
        </FormLayout>

  );
};

export default CalloutBannerCard;
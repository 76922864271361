import React from 'react'
import { LegacyCard, FormLayout, TextField, TextContainer, Text } from "@shopify/polaris";
import styled from 'styled-components'
import update from 'immutability-helper'
import ManualRules from './manualRules'
import ShopBrain from './shopBrain'
import VersionColor from './versionColor'
import OfferTypeSelector from "../common/offerTypeSelector/offerTypeSelector";
import CalloutBannerCard from "../common/calloutBannerCard/calloutBannerCard";
import CardWrapper from '../../../../components/cardWrapper';
const CheckoutOfferTypeWrapper = styled.div`
    margin-bottom: 20px;
    .rule-type {
        padding-top: 1.6rem;
        .rule-type-subtitle {
            margin: 0px 0 20px;
        }
        .rule-type-option {
            position: relative;
            border: 2px solid #AAA;
            border-radius: 4px;
            padding: 10px 10px;
            margin-bottom: 25px;
            .rule-type-image {
                text-align: center;
                img {
                    width: 85px;
                }
            }
            .rule-type-image:nth-of-type(2) {
                img {
                    width: 96px;
                }
            }
            .rule-type-desp {
                max-width: 210px;
            }
        }
        .active {
            border: 2px solid #008060;
        }
    }
`
function CheckoutOfferType(props) {
    
    const { host, token, shopBrainType, versionAnalytics, versionData, setVersionData, checkoutOfferType, handleCheckoutOfferTypeChange, shop, addSmartRule, state, setState, version, setVersion, productPickerShow, setProductPickerShow, error, setError, productEdit, setProductEdit, currency, locale, currencySymbol, setCheckoutOfferType, existingOffer } = props
    
    return (
        <CheckoutOfferTypeWrapper>
        <CardWrapper title="Name your Upsell" subtitle="Name your Upsell, Product Selection">
           <FormLayout>
            <Text variant="headingSm" as="h3">Name Your offer</Text>
            <TextField 
                    label="Internal offer nickname" 
                    placeholder="Your shoppers won’t see this." 
                    labelHidden={true}
                    onChange={(val) => {
                        setState(update(state, { name: { $set: val }}))
                    }}
                    value={state.name}
                />
            {state.id ?
            <TextContainer spacing="tight">
            <Text  variant="bodyMd" as="span" tone="subdued">Offer ID:{state.id}</Text>
            </TextContainer>
            :
            false
            }
           </FormLayout>

           <OfferTypeSelector
              checkoutOfferType={checkoutOfferType}
              setCheckoutOfferType={setCheckoutOfferType}
              shopBrainType={shopBrainType}
              state={state}
              setState={setState}
              existingOffer={existingOffer}
           />
        </CardWrapper>
        <CardWrapper title="Content" subtitle="Callout Banner">
          <div className={version ? ("A"===version?'version-a':'version-b'):''}>
            <CalloutBannerCard state={state} setState={setState} fields={['banner']}/>
          </div>
        </CardWrapper>

         {"manual"===checkoutOfferType ?
          <ManualRules host={host} token={token} versionAnalytics={versionAnalytics} versionData={versionData} setVersionData={setVersionData} version={version} setVersion={setVersion} checkoutOfferType={checkoutOfferType} shop={shop} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} setError={setError} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} locale={locale} currencySymbol={currencySymbol}/>
          :
          <ShopBrain host={host} token={token} versionAnalytics={versionAnalytics} versionData={versionData} setVersionData={setVersionData} version={version} setVersion={setVersion} checkoutOfferType={checkoutOfferType} shop={shop} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currency={currency} locale={locale} currencySymbol={currencySymbol} addSmartRule={addSmartRule} />
         }
        </CheckoutOfferTypeWrapper>
    );
}

export default CheckoutOfferType;